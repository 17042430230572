<template>
    <b-container class="account-wizard bg-light page-wrapper createMultipleTransactions">
        <b-row class="main-content-wrapper" no-gutters>
          <b-col cols="12">
              <b-breadcrumb :items="breadcrumbItems" class="dark"></b-breadcrumb>
          </b-col>
          <b-col cols="12">
              <h1 class="text-left">{{ $t(translationPath + 'heading')}}</h1>
          </b-col>

          <b-col cols="12" class="col-lg-10 col-xl-8 sidebar-text" >
            <i18n :path="translationPath+'titles_and_descriptions.intro_p1'" tag="p" class="text-gray">
            </i18n>
          </b-col>

          <b-col cols="12" class="mt-3 mb-2">
            <div class="bordered-table px-0">

              <b-tabs card class="white-tabs px-0 mx-0" ref="Tabs">
                <b-tab :title="$t(translationPath + 'tabs.simple')" class="tab-simple">
                  <b-row no-gutters>
                    <b-col cols="12" lg="10">
                      <b-form-group
                        :invalid-feedback="$t(translationPath + 'errors.source')"
                        :label="$t( translationPath + 'source')"
                        class="mb-0"
                        label-cols="3"
                      >
                        <custom-select
                          v-model="source"
                          :class="{ 'is-invalid': showSourceValidateClass && !source, 'is-valid': showSourceValidateClass && source }"
                          :noResultText="$t('common.no_option_found').toString()"
                          :options="sourceOptions['assetliability_importable']"
                          :placeholder="''"
                          :search-placeholder="$t('common.type_to_filter').toString()"
                          :sortGroupLabel="false"
                          class="mb-0"
                          groupingLabel="title"
                          groupingValues="accounts"
                          label="title"
                        />
                        <template slot="description">
                          {{ $t(translationPath + 'source_description') }}
                        </template>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" lg="2" v-if="latestTransactionDate" class="latestTransactionDate">
                      <label>{{ $t('common.latest_transaction_date') }}: {{ latestTransactionDate }}</label>
                    </b-col>
                  </b-row>

                  <b-table class="spirecta-simple-table accounts-table mt-4" show-empty hover striped
                    :items="transactionsItems"
                    :fields="transactionsFields"
                    stacked="lg"
                    :emptyText="'No New Transaction'"
                    :tbody-tr-class="rowClass"
                  >
                    <template v-slot:cell(title)="row" class="test">
                        <template v-if="editTransactions.indexOf(row.item.index) > -1">
                          <b-form-group
                            class="mb-0"
                            :state="row.item.errors.title"
                            :invalid-feedback="row.item.errors.title === false ? $t(translationPath + 'errors.title'): ''"
                          >
                            <b-form-input v-model.trim="row.item.title" @input="(value) => validateSingleColumn(value, row, 'title')" :state="row.item.errors.title"/>
                          </b-form-group>
                        </template>
                        <template v-else>
                          {{ row.value }}
                        </template>
                    </template>

                    <!--Amount-->
                    <template v-slot:cell(amount)="row">
                      <template v-if="editTransactions.indexOf(row.item.index) > -1">
                        <b-form-group
                          class="mb-0"
                          :state="row.item.errors.amount"
                          :invalid-feedback="row.item.errors.amount === false ? $t(translationPath + 'errors.amount'): ''"
                        >
                          <currency-input
                            v-model="row.item.amount"
                            :input-class="{'is-invalid': row.item.errors.amount === false, 'is-valid': row.item.errors.amount === true }"
                            @input="(value) => validateSingleColumn(value, row, 'amount')"
                          />
                        </b-form-group>
                      </template>
                      <template v-else>
                        {{ row.value }}
                      </template>
                    </template>
                    <!--type-->
                    <template v-slot:cell(type)="row">
                      <template v-if="editTransactions.indexOf(row.item.index) > -1">
                        <b-form-group
                          class="mb-0"
                          :state="row.item.errors.type"
                          :invalid-feedback="row.item.errors.type === false ? $t(translationPath + 'errors.type'): ''"
                        >
                          <b-form-select :options="typeOptions" v-model="row.item.type" :state="row.item.errors.type"  @input="(value) => validateSingleColumn(value, row, 'type')"></b-form-select>
                        </b-form-group>
                      </template>
                      <template v-else>
                        {{ row.value }}
                      </template>
                    </template>
                    <!--date-->
                    <template v-slot:cell(date)="row">
                      <template v-if="editTransactions.indexOf(row.item.index) > -1">
                        <b-form-group
                          class="mb-0"
                          :state="row.item.errors.date"
                          :invalid-feedback="row.item.errors.date === false ? $t(translationPath + 'errors.date'): ''"
                        >
                          <datepicker
                            input-class="form-control"
                            v-model="row.item.date"
                            :language="selectedLang"
                            :format="'yyyy-MM-dd'"
                            :state="row.item.errors.date"
                            :class="{'is-invalid': row.item.errors.date === false, 'is-valid': row.item.errors.date === true }"
                            :monday-first="startWeekByMonday"
                            :typeable="true"
                            @input="(value) => validateSingleColumn(value, row, 'date')"
                          ></datepicker>
                        </b-form-group>
                      </template>
                      <template v-else>
                        {{ row.value }}
                      </template>
                    </template>
                    <!--category-->
                    <template v-slot:cell(category)="row">
                      <template v-if="editTransactions.indexOf(row.item.index) > -1">
                        <b-form-group
                          class="mb-0"
                          :state="row.item.errors.category"
                          :invalid-feedback="row.item.errors.category === false ? $t(translationPath + 'errors.category'): ''"
                        >
                          <custom-select
                            v-model="row.item.category"
                            :options="row.item.type && categoryOptions[getCategoryType(row.item.type)] ? categoryOptions[getCategoryType(row.item.type)] : []"
                            groupingValues="accounts"
                            groupingLabel="title"
                            :placeholder="''"
                            :search-placeholder="$t('common.type_to_filter').toString()"
                            :noResultText="$t('common.no_option_found').toString()"
                            :sortGroupLabel="false"
                            @open="handleCategoryOpen(row.item.type)"
                            @input="(value) => validateSingleColumn(value, row, 'category')"
                            @select="(value) => row.item.percentage = value.default_active_percentage"
                            v-bind:class="{'is-invalid': row.item.errors.category === false, 'is-valid': row.item.errors.category === true }"
                            label="title"
                          />
                        </b-form-group>
                      </template>
                      <template v-else>
                        {{ row.value }}
                      </template>
                    </template>

                    <template v-slot:cell(percentage)="row">
                      <template v-if="editTransactions.indexOf(row.item.index) > -1">
                          <percentage-input
                            v-model="row.item.percentage"
                            :class="{'is-invalid': row.item.errors.percentage === false, 'is-valid': row.item.errors.percentage === true }"
                            @input="(value) => validateSingleColumn(value, row, 'percentage')"
                          ></percentage-input>

                      </template>
                      <template v-else>
                        {{ row.value }}
                      </template>
                    </template>
                    <template v-slot:cell(action)="row">
                      <b-button @click="copyRow(row)" :title="$t('common.duplicate')" class="plain-btn action-button mr-2"><i class="flaticon solid switch-window text-primary"></i></b-button>
                      <b-button @click="deleteRow(row)" :title="$t('common.delete')" class="plain-btn action-button"><i class="flaticon solid trash-3 text-danger"></i></b-button>
                    </template>
                  </b-table>
                </b-tab>
                <b-tab :title="$t(translationPath + 'tabs.advanced')" class="tab-advanced">

                  <div v-html="$t(translationPath + 'description_advanced')" class="text-regular"></div>
                  <b-table class="spirecta-simple-table accounts-table mt-4" show-empty hover striped
                    :items="transactionsItems"
                    :fields="transactionsFieldsAdvanced"
                    stacked="lg"
                    :emptyText="'No New Transaction'"
                    :tbody-tr-class="rowClass"
                  >
                    <template v-slot:cell(title)="row" class="test">
                      <template v-if="editTransactions.indexOf(row.item.index) > -1">
                        <b-form-group
                          class="mb-0"
                          :state="row.item.errors.title"
                          :invalid-feedback="row.item.errors.title === false ? $t(translationPath + 'errors.title'): ''"
                        >
                          <b-form-input v-model.trim="row.item.title" @input="(value) => validateSingleColumn(value, row, 'title')" :state="row.item.errors.title"/>
                        </b-form-group>
                      </template>
                      <template v-else>
                        {{ row.value }}
                      </template>
                    </template>

                    <!--Amount-->
                    <template v-slot:cell(amount)="row">
                      <template v-if="editTransactions.indexOf(row.item.index) > -1">
                        <b-form-group
                          class="mb-0"
                          :state="row.item.errors.amount"
                          :invalid-feedback="row.item.errors.amount === false ? $t(translationPath + 'errors.amount'): ''"
                        >
                          <currency-input
                            v-model="row.item.amount"
                            :input-class="{'is-invalid': row.item.errors.amount === false, 'is-valid': row.item.errors.amount === true }"
                            @input="(value) => validateSingleColumn(value, row, 'amount')"
                          />
                        </b-form-group>
                      </template>
                      <template v-else>
                        {{ row.value }}
                      </template>
                    </template>

                    <!--date-->
                    <template v-slot:cell(date)="row">
                      <template v-if="editTransactions.indexOf(row.item.index) > -1">
                        <b-form-group
                          class="mb-0"
                          :state="row.item.errors.date"
                          :invalid-feedback="row.item.errors.date === false ? $t(translationPath + 'errors.date'): ''"
                        >
                          <datepicker
                            input-class="form-control"
                            v-model="row.item.date"
                            :language="selectedLang"
                            :format="'yyyy-MM-dd'"
                            :state="row.item.errors.date"
                            :class="{'is-invalid': row.item.errors.date === false, 'is-valid': row.item.errors.date === true }"
                            :monday-first="startWeekByMonday"
                            :typeable="true"
                            @input="(value) => validateSingleColumn(value, row, 'date')"
                          ></datepicker>
                        </b-form-group>
                      </template>
                      <template v-else>
                        {{ row.value }}
                      </template>
                    </template>

                    <!--category-->
                    <template v-slot:cell(category)="row">
                      <template v-if="editTransactions.indexOf(row.item.index) > -1">
                        <b-form-group
                          class="mb-0"
                          :state="row.item.errors.category"
                          :invalid-feedback="row.item.errors.category === false ? $t(translationPath + 'errors.category'): ''"
                        >
                          <custom-select
                            v-model="row.item.category"
                            :options="categoryOptions.all"
                            groupingValues="accounts"
                            groupingLabel="title"
                            :placeholder="''"
                            :search-placeholder="$t('common.type_to_filter').toString()"
                            :noResultText="$t('common.no_option_found').toString()"
                            :sortGroupLabel="false"
                            @open="handleCategoryOpen(row.item.type)"
                            @input="(value) => validateSingleColumn(value, row, 'category')"
                            @select="(value) => row.item.percentage = value.default_active_percentage"
                            v-bind:class="{'is-invalid': row.item.errors.category === false, 'is-valid': row.item.errors.category === true }"
                            label="title"
                          />
                        </b-form-group>
                      </template>
                      <template v-else>
                        {{ row.value }}
                      </template>
                    </template>

                    <!--source-->
                    <template v-slot:cell(source)="row">
                      <template v-if="editTransactions.indexOf(row.item.index) > -1">
                        <b-form-group
                          class="mb-0"
                          :state="row.item.errors.source"
                          :invalid-feedback="row.item.errors.source === false ? $t(translationPath + 'errors.source'): ''"
                        >
                          <custom-select
                            v-model="row.item.source"
                            :options="sourceOptions.all"
                            groupingValues="accounts"
                            groupingLabel="title"
                            :placeholder="''"
                            :search-placeholder="$t('common.type_to_filter').toString()"
                            :noResultText="$t('common.no_option_found').toString()"
                            :sortGroupLabel="false"
                            @open="handleCategoryOpen(row.item.type)"
                            @input="(value) => validateSingleColumn(value, row, 'source')"
                            v-bind:class="{'is-invalid': row.item.errors.source === false, 'is-valid': row.item.errors.source === true }"
                            label="title"
                          />
                        </b-form-group>
                      </template>
                      <template v-else>
                        {{ row.value }}
                      </template>
                    </template>

                    <template v-slot:cell(percentage)="row">
                      <template v-if="editTransactions.indexOf(row.item.index) > -1">
                          <percentage-input
                            v-model="row.item.percentage"
                            :class="{'is-invalid': row.item.errors.percentage === false, 'is-valid': row.item.errors.percentage === true }"
                            @input="(value) => validateSingleColumn(value, row, 'percentage')"
                          ></percentage-input>

                      </template>
                      <template v-else>
                        {{ row.value }}
                      </template>
                    </template>
                    <template v-slot:cell(action)="row">
                      <b-button @click="copyRow(row)" :title="$t('common.duplicate')" class="plain-btn action-button mr-2"><i class="flaticon solid switch-window text-primary"></i></b-button>
                      <b-button @click="deleteRow(row)" :title="$t('common.delete')" class="plain-btn action-button"><i class="flaticon solid trash-3 text-danger"></i></b-button>
                    </template>
                  </b-table>
                </b-tab>
              </b-tabs>
              <b-row no-gutters>
                <b-col class="pl-4">
                  <b-button variant="outline-secondary" class="btn-sm addTransactionButton" @click.prevent="addTransaction">{{ $t(translationPath + 'add_new_transactions') }}</b-button>
                </b-col>
                <b-col class="text-left text-md-right pr-4">
                  <b-button variant="primary" @click.prevent="saveTransaction" class="saveButton" :disabled="transactionsItems.length === 0 || isInProcess"> {{ $t('common.save') }}</b-button>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
        <!--Success Modal-->
        <success-modal
          :title="$t(translationPath + 'success_modal.title').toString()"
          :message="$t(translationPath + 'success_modal.description').toString()"
          :button="$t(translationPath + 'success_modal.primary_button').toString()"
          redirect="/transactions/view"
          orLink="/redirect?to=createMultiple"
          :orLinkText="$t(translationPath + 'success_modal.secondary_link_text').toString()"
          @hide="$router.push('/redirect?to=createMultiple')"
        ></success-modal>
        <b-row v-if="debug">
          {{ transactions }}
        </b-row>
    </b-container>
</template>

<script>
import CustomSelect from '@/components/common/CustomSelect'
import PercentageInput from '@/components/common/PercentageInput'
import Datepicker from 'vuejs-datepicker'
import { en, da, sv } from 'vuejs-datepicker/dist/locale'
import CurrencyInput from '@/components/common/CurrencyInput'
import { mapState } from 'vuex'
import axios from 'axios'
import _ from 'lodash'
import SuccessModal from '@/components/modals/SuccessModal'
import titleMixins from '@/mixins/title'

export default {
  name: 'multipleTransactions',
  mixins: [titleMixins],
  components: { Datepicker, CurrencyInput, CustomSelect, SuccessModal, PercentageInput },
  data () {
    return {
      startWeekByMonday: Object.prototype.hasOwnProperty.call(process.env, 'VUE_APP_DATEPICKER_START_DAY_BY_MONDAY') && process.env.VUE_APP_DATEPICKER_START_DAY_BY_MONDAY ? process.env.VUE_APP_DATEPICKER_START_DAY_BY_MONDAY === 'true' : false,
      newIndex: 0,
      transactionsItems: [],
      editTransactions: [],
      currencyInputPercentageParam: {
        prefix: '',
        suffix: ' %'
      },
      datepickerLang: {
        en: en,
        sv: sv,
        da: da
      },
      defaultRowCount: 5,
      categoryOptions: {},
      sourceOptions: {},
      source: null,
      showSourceValidateClass: false,
      translationPath: 'transactions.create_multiple.',
      debug: false,
      latestTransactionDate: null,
      isInProcess: false
    }
  },
  computed: {
    ...mapState('user', ['currentUser', 'currentCOA']),
    breadcrumbItems () {
      return [
        { text: this.$t('common.header.header_menu.transactions.transactions'), to: '/transactions/import/all' },
        { text: '', to: '', active: true }
      ]
    },
    selectedLangFormat () {
      return this.$i18n.locale
    },
    transactionsFields () {
      return [
        { label: this.$t(this.translationPath + 'date'), key: 'date', sortable: false },
        { label: this.$t(this.translationPath + 'title'), key: 'title', sortable: false },
        { label: this.$t(this.translationPath + 'type'), key: 'type', sortable: false },
        { label: this.currentUser && this.currentUser.strict_accounting_mode ? this.$t(this.translationPath + 'account') : this.$t(this.translationPath + 'category'), key: 'category', sortable: false },
        // { label: this.$t(this.translationPath + 'source'), key: 'source', sortable: false },
        { label: this.$t(this.translationPath + 'amount'), key: 'amount', sortable: false },
        { label: this.$t(this.translationPath + 'percentage'), key: 'percentage', sortable: false },
        { label: '', key: 'action', sortable: false, class: 'text-right' }
      ]
    },
    transactionsFieldsAdvanced () {
      return [
        { label: this.$t(this.translationPath + 'date'), key: 'date', sortable: false },
        { label: this.$t(this.translationPath + 'title'), key: 'title', sortable: false },
        { label: this.$t(this.translationPath + 'credit_account'), key: 'category', sortable: false },
        { label: this.$t(this.translationPath + 'debit_account'), key: 'source', sortable: false },
        { label: this.$t(this.translationPath + 'amount'), key: 'amount', sortable: false },
        { label: this.$t(this.translationPath + 'percentage'), key: 'percentage', sortable: false },
        { label: '', key: 'action', sortable: false, class: 'text-right' }
      ]
    },
    typeOptions () {
      return [
        { value: 'deposit', text: this.$t('common.transaction_types.deposit') },
        { value: 'withdrawal', text: this.$t('common.transaction_types.withdrawal') },
        { value: 'refund', text: this.$t('common.transaction_types.refund') },
        { value: 'transfer_to', text: this.$t('common.transaction_types.transfer_to') },
        { value: 'transfer_from', text: this.$t('common.transaction_types.transfer_from') }
      ]
    },
    selectedLang () {
      return this.datepickerLang[this.$i18n.locale]
    },
    transactions () {
      const safeTransactions = this.transactionsItems.map(item => {
        let AccountTransaction = {}
        let BankTransaction = {}
        if (item.type === 'deposit' || item.type === 'withdrawal') {
          AccountTransaction = {
            account_id: item.category ? item.category.id : null,
            offset_account_id: item.source ? item.source.id : null,
            active_percentage: item.percentage ? item.percentage : null,
            dctype: item.type === 'withdrawal' ? 'dr' : 'cr',
            amount: item.amount
          }
          BankTransaction = {
            account_id: item.source ? item.source.id : null,
            offset_account_id: item.category ? item.category.id : null,
            active_percentage: item.percentage ? item.percentage : null,
            dctype: item.type === 'withdrawal' ? 'cr' : 'dr',
            amount: item.amount
          }
        } else if (item.type === 'refund') {
          item.type = 'withdrawal'
          AccountTransaction = {
            account_id: item.category ? item.category.id : null,
            offset_account_id: item.source ? item.source.id : null,
            active_percentage: item.percentage ? item.percentage : null,
            dctype: 'cr',
            amount: item.amount
          }
          BankTransaction = {
            account_id: item.source ? item.source.id : null,
            offset_account_id: item.category ? item.category.id : null,
            active_percentage: item.percentage ? item.percentage : null,
            dctype: 'dr',
            amount: item.amount
          }
        } else if (item.type === 'transfer_from') {
          item.type = 'transfer'
          AccountTransaction = {
            account_id: item.category ? item.category.id : null,
            offset_account_id: item.source ? item.source.id : null,
            active_percentage: item.percentage ? item.percentage : null,
            dctype: 'cr',
            amount: item.amount
          }
          BankTransaction = {
            account_id: item.source ? item.source.id : null,
            offset_account_id: item.category ? item.category.id : null,
            active_percentage: item.percentage ? item.percentage : null,
            dctype: 'dr',
            amount: item.amount
          }
        } else if (item.type === 'transfer_to') {
          item.type = 'transfer'
          AccountTransaction = {
            account_id: item.category ? item.category.id : null,
            offset_account_id: item.source ? item.source.id : null,
            active_percentage: item.percentage ? item.percentage : null,
            dctype: 'dr',
            amount: item.amount
          }
          BankTransaction = {
            account_id: item.source ? item.source.id : null,
            offset_account_id: item.category ? item.category.id : null,
            active_percentage: item.percentage ? item.percentage : null,
            dctype: 'cr',
            amount: item.amount
          }
        }
        const entries = [AccountTransaction, BankTransaction]
        return Object.assign({}, { title: item.title, date: this.$moment(item.date).format('YYYY-MM-DD'), type: item.type, description: null, entries: entries })
      })
      return safeTransactions
    },
    transactionsAdvanced () {
      const safeTransactions = this.transactionsItems.map(item => {
        const entries = [
          {
            account_id: item.category ? item.category.id : null,
            offset_account_id: item.source ? item.source.id : null,
            active_percentage: item.percentage ? item.percentage : null,
            dctype: 'cr',
            amount: item.amount
          },
          {
            account_id: item.source ? item.source.id : null,
            offset_account_id: item.category ? item.category.id : null,
            active_percentage: item.percentage ? item.percentage : null,
            dctype: 'dr',
            amount: item.amount
          }
        ]

        return Object.assign({}, { title: item.title, date: this.$moment(item.date).format('YYYY-MM-DD'), type: item.type, description: null, entries: entries })
      })

      return safeTransactions
    },
    titleMeta () {
      return this.$t(this.translationPath + 'heading')
    }
  },
  created () {
    this.loadOptions()
    this.defaultTransactionsRows()
  },
  watch: {
    titleMeta: {
      handler () {
        this.setPageTitle(this.titleMeta)
      },
      immediate: true
    },
    '$i18n.locale' () {
      for (const type in this.categoryOptions) {
        this.categoryOptions[type].map(item => {
          item.title = this.$t('common.' + item.type) + ' / ' + item.rawTitle
        })
      }
    },
    source: 'loadLatestTransactionDate'
  },
  methods: {
    deleteRow (row) {
      const removedIndex = row.item.index
      this.transactionsItems = this.transactionsItems.filter(item => item.index !== removedIndex)

      this.transactionsItems.map(item => {
        item.index = item.index > removedIndex ? item.index - 1 : item.index
      })
    },
    defaultTransactionsRows () {
      if (this.defaultRowCount > 0) {
        for (let i = 1; i <= this.defaultRowCount; i++) {
          this.addTransaction()
        }
      }
    },
    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (item.errors.duplicate === false) {
        return 'duplicate-error'
      }
    },
    handleCategoryOpen (type) {
      if (!Object.prototype.hasOwnProperty.call(this.categoryOptions, type)) {
        this.loadOptions()
      }
    },
    handleSourceOpen (type) {
      if (!Object.prototype.hasOwnProperty.call(this.sourceOptions, type)) {
        this.loadOptions()
      }
    },
    loadOptions () {
      this.loadAccount('income,expense,asset,liability')
        .then(data => {
          this.categoryOptions = data
          this.sourceOptions = data
          this.categoryOptions.incomeExpense = data.income.concat(data.expense)
          this.categoryOptions.all = this.categoryOptions.incomeExpense.concat(this.categoryOptions.assetliability)
          this.sourceOptions.all = this.categoryOptions.all
        })
        .catch(err => {
          console.error(err)
        })
    },
    validateSingleColumn (value, row, type) {
      if (!value && type === 'title') {
        row.item.errors.title = false
      } else if (value && type === 'title') {
        row.item.errors.title = true
      }
      if (!value && value !== 0 && type === 'amount') {
        row.item.errors.amount = false
      } else if (value && type === 'amount') {
        row.item.errors.amount = true
      }
      if (!value && type === 'type') {
        row.item.errors.type = false
      } else if (value && type === 'type') {
        row.item.errors.type = true
      }
      if (!value && type === 'date') {
        row.item.errors.date = false
      } else if (value && type === 'date') {
        row.item.errors.date = true
      }
      if (!value && type === 'category') {
        row.item.errors.category = false
      } else if (value && type === 'category') {
        row.item.errors.category = true
      }
      if (!value && type === 'source') {
        row.item.errors.source = false
      } else if (value && type === 'source') {
        row.item.errors.source = true
      }
      if (type === 'percentage') {
        row.item.errors.percentage = value >= 0 && value <= 100
      }
    },
    validateTransactions (row) {
      if (!row.title) {
        row.errors.title = false
      } else if (row.title) {
        row.errors.title = true
      }
      if (!row.amount) {
        row.errors.amount = false
      } else if (row.amount && row.amount <= 0) {
        row.errors.amount = false
      } else if (row.amount) {
        row.errors.amount = true
      }
      if (this.$refs.Tabs.currentTab === 0 && !row.type) {
        row.errors.type = false
      } else if ((this.$refs.Tabs.currentTab === 0 && row.type) || this.$refs.Tabs.currentTab === 1) {
        row.errors.type = true
      }
      if (!row.date) {
        row.errors.date = false
      } else if (row.date) {
        row.errors.date = true
      }
      if (!row.category) {
        row.errors.category = false
      } else if (row.category) {
        row.errors.category = true
      }
      if ((this.$refs.Tabs.currentTab === 0 && !this.source) || (this.$refs.Tabs.currentTab === 1 && !row.source)) {
        row.errors.source = false
      } else if ((this.$refs.Tabs.currentTab === 0 && this.source) || (this.$refs.Tabs.currentTab === 1 && row.source)) {
        row.errors.source = true
      }

      // Check percentage
      row.errors.percentage = row.percentage >= 0 && row.percentage <= 100

      return row
    },
    getCategoryType (type) {
      if (type === 'deposit') {
        return 'income'
      } else if (type === 'withdrawal' || type === 'refund') {
        return 'expense'
      } else {
        return 'assetliability'
      }
    },
    disableEditStatus (index) {
      const indexRows = this.editTransactions.indexOf(index)
      this.editTransactions.slice(indexRows, 1)
    },
    updateSingleRow (index) {
      this.disableEditStatus(index)
    },
    loadAccount (fetchType) {
      return axios.get(`${process.env.VUE_APP_ROOT_API}/accounts/groups/for/multiselect?account_type=${fetchType}&log=createMultipleTransactions&include_networth_account=1`)
        .then(response => response.data.data)
        .then(responseData => {
          const filteredData = responseData.filter(group => {
            let isImportable = false
            group.accounts.map(account => {
              if (account.is_importable) {
                isImportable = true
              }
            })
            return isImportable
          })
          filteredData.map(group => {
            group.accounts = group.accounts.filter(account => account.is_importable)
          })
          const optionsData = responseData.map(items => {
            return Object.assign(items, {
              title: this.$t('common.' + items.type.toLowerCase()) + ' / ' + items.title,
              accounts: items.accounts,
              rawTitle: items.title
            })
          })
          return {
            income: optionsData.filter(item => item.type === 'income'),
            expense: optionsData.filter(item => item.type === 'expense'),
            asset: optionsData.filter(item => item.type === 'asset'),
            assetliability: optionsData.filter(item => ['asset', 'liability'].indexOf(item.type) > -1),
            assetliability_importable: filteredData.filter(item => ['asset', 'liability'].indexOf(item.type) > -1)
          }
        })
        .catch(err => {
          console.error(err)
          return {
            options: [],
            loading: false
          }
        })
    },
    addTransaction () {
      const newTransaction = {
        title: null,
        amount: null,
        type: null,
        date: new Date(),
        category: null,
        source: null,
        percentage: 0,
        index: 0,
        errors: {
          amount: null,
          title: null,
          type: null,
          source: null,
          date: null,
          category: null,
          percentage: null,
          duplicate: true
        }
      }
      this.newIndex = this.newIndex + 1
      Object.assign(newTransaction, { index: this.newIndex })
      this.editTransactions.push(this.newIndex)
      this.transactionsItems.push(newTransaction)
    },
    copyRow (row) {
      const newTransaction = _.cloneDeep(row.item)
      this.newIndex = this.newIndex + 1
      this.transactionsItems.map(item => {
        if (item.index > row.index) {
          item.index++
        }
      })
      Object.assign(newTransaction, { index: row.index + 1 })
      this.editTransactions.push(this.newIndex)
      this.transactionsItems.push(newTransaction)
      this.transactionsItems.sort((a, b) => a.index > b.index ? 1 : -1)
    },
    removeEmptyRows () {
      let currentLength = this.transactionsItems.length

      this.transactionsItems = this.transactionsItems.filter(item => {
        if (currentLength === 1) {
          // Leave at least one row even if row is empty.
          return true
        }

        if (
          item.title === null &&
          !item.amount &&
          item.type === null &&
          item.category === null &&
          item.source === null &&
          item.percentage === 0
        ) {
          currentLength--
          return false
        }
        return true
      })
    },
    saveTransaction () {
      this.isInProcess = true
      this.showSourceValidateClass = !this.$refs.Tabs.currentTab
      this.removeEmptyRows()
      const validateTransactions = this.transactionsItems.filter(items => {
        const validItem = this.validateTransactions(items)
        const allInvalidata = Object.keys(validItem.errors).filter(item => validItem.errors[item] === false)
        return allInvalidata.length === 0
      })
      validateTransactions.map(item => {
        if (this.$refs.Tabs.currentTab === 0) {
          item.source = this.source
        } else {
          if (item.category.type === 'income') {
            item.type = 'deposit'
          } else if (item.category.type === 'expense') {
            item.type = 'withdrawal'
          } else {
            item.type = 'transfer'
          }
        }
      })
      if (validateTransactions.length > 0) {
        const checkDuplicatesData = {
          transactions: this.$refs.Tabs.currentTab === 0 ? this.transactions : this.transactionsAdvanced
        }
        axios.post(`${process.env.VUE_APP_ROOT_API}/transactions/with/entries`, checkDuplicatesData)
          .then(response => response.data.data)
          .then(responseData => {
            this.transactionsItems = []
            this.$bvToast.toast(this.$t(this.translationPath + 'toast.created').toString(), {
              title: this.$t(this.translationPath + 'toast.heading').toString(),
              variant: 'success',
              solid: true,
              'auto-hide-delay': 3000
            })
            this.defaultTransactionsRows()
            this.$bvModal.show('successModal')
          }).catch(err => {
            console.error(err)
          }).finally(() => {
            this.isInProcess = false
          })
      } else {
        this.isInProcess = false
        this.$bvToast.toast(this.$t(this.translationPath + 'toast.errors').toString(), {
          title: this.$t(this.translationPath + 'toast.heading').toString(),
          variant: 'danger',
          solid: true,
          'auto-hide-delay': 3000
        })
      }
    },
    loadLatestTransactionDate (account) {
      const { id } = account
      axios.get(`${process.env.VUE_APP_ROOT_API}/recent/transaction/${id}`)
        .then((response) => response.data.data)
        .then((response) => {
          const transactions = response[0]
          this.latestTransactionDate = transactions.length > 0 ? transactions[0].date : null
        })
    }
  }
}
</script>

<style lang="scss">
.createMultipleTransactions {
  .actionBtn {
    font-size: 12px;
  }
  .latestTransactionDate {
    font-size: 14px;
  }
  .bordered-table {
    thead {
      th {
        border-top: none;
      }
    }
  }
  input.form-control {
    font-size: 13px;
    padding: 0.375rem;
    height: 39px;
  }
  .custom-select {
    height: 39px !important;
  }
  .addTransactionButton {
    padding: 5px 15px;
  }
  .vdp-datepicker__calendar {
    .cell {
      &.selected {
        background: transparent;
        color: #36b373;
      }
      &:hover {
        background: transparent !important;
        color: #36b373;
        border: none !important;
      }
    }
  }
  .overflow-x-scroll {
    @media screen and (min-width: 1181px) and (max-width: 1440px) {
      overflow-x: scroll;
    }
  }
  .spirecta-simple-table tbody td:nth-child(1) {
    font-weight: normal;
  }
  .is-invalid {
    .input-group-text {
      border-color:#EF5350;
    }
  }
  .is-valid {
    .input-group-text {
      border-color:#36b37E;
    }
  }
  .spirecta-simple-table tbody tr.duplicate-error {
    td {
      .form-group {
        .form-control {
          border-color: #EF5350 !important;
        }
        .input-group-text {
          border-color: #EF5350 !important;
        }
        .custom-select {
          border-color: #EF5350 !important;
        }
        .ember-input {
          border-color: #EF5350 !important;
        }
      }
    }
  }
  .spirecta-simple-table tbody tr td {
    padding-top: 20px;
    padding-bottom: 20px;
    position: relative;
    @media screen and (min-width: 769px) {
      min-width: 150px;
    }
    .suggestion-list ul .ember-grouping-list li {
      font-size:81%;
    }
    .suggestion-list ul li.ember-grouping span {
      font-size: 71%;
    }
  }

  @media only screen and (min-width: 992px) {
    .tab-simple .spirecta-simple-table tbody tr td {
      &:nth-child(3) {min-width:50px;max-width:100px;}
      &:nth-child(1) {min-width: 105px; max-width: 105px;}
      &:nth-child(6) {min-width:75px;max-width:110px;}
      &:nth-child(5) {min-width:220px;}
      &:nth-child(4) {min-width:220px;}
      &:nth-child(7) {min-width:50px;max-width:55px;}
    }

    .tab-advanced .spirecta-simple-table tbody tr td {
      &:nth-child(1) {min-width: 105px; max-width: 105px;}
      &:nth-child(3) {min-width:150px; max-width: 220px;}
      &:nth-child(4) {min-width:200px;}
      &:nth-child(5) {min-width:200px;}
      &:nth-child(6) {min-width:75px;max-width:110px;}
      &:nth-child(7) {min-width:50px;max-width:55px;}
    }
  }

  @media only screen and (max-width: 991.98px) {
    .table.b-table.b-table-stacked-lg > tbody > tr > [data-label]::before {
      padding-top:10px;
    }
  }

  .invalid-feedback {
    position: absolute;
    bottom: 2px;
    font-size: 11px;
    @media screen and (max-width:768px) {
      font-size: 13px;
    }
  }
  .saveButton {
    padding: 5px 25px;
  }
  .action-button {
    padding: 0;
    border: none;
    line-height: normal;
    i {
      margin: 0;
      font-size: 13px;
    }
    &:hover {
      background: transparent;
    }
    &:active {
      background-color: transparent !important;
      border-color: transparent !important;
    }
    &:focus {
      background: transparent;
      box-shadow: none !important;
    }
  }

}
</style>
